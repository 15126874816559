<template>
  <main class="main about">
    <page-slider
      :slides="pageData.acf.banner_group.slides"
      v-if="pageData && pageData.acf && pageData.acf.show_banner"
    ></page-slider>
    <div class="py-4" v-else></div>
    <b-container
      fluid="xl"
      class="my-5"
      v-if="pageData && pageData.acf && pageData.acf.section_1_active"
    >
      <section-title>{{ pageData.acf.section_1_content.title }}</section-title>
      <p
        class="text-primary text-uppercase text-center my-3"
        v-html="pageData.acf.section_1_content.text"
      ></p>
    </b-container>

    <blue-wave-container
      class="blue-wave"
      v-if="pageData && pageData.acf && pageData.acf.section_2_active"
    >
      <b-container fluid="xl" class="my-5">
        <div v-for="item in pageData.acf.section_2_repeater">
          <h4 class="text-bold">{{ item.content_group.title }}</h4>
          <p class="my-5" v-html="item.content_group.text"></p>
        </div>

        <a
          class="
            btn btn-primary
            p-3
            mb-5
            text-small text-uppercase
            w-100
            text-bold
            border-light
          "
          :href="pageData.acf.section_2_read_more_button.url"
          v-if="pageData.acf.section_2_read_more_button.url"
        >
          {{ pageData.acf.section_2_read_more_button.anchor }}
        </a>
      </b-container>
    </blue-wave-container>

    <div
      class="worth-reading"
      v-if="pageData && pageData.acf && pageData.acf.section_3_active"
    >
      <div class="worth-reading--overlay">
        <b-container fluid="xl" class="worth-reading--container">
          <div v-for="item in pageData.acf.section_3_repeater">
            <h3 class="text-center text-white">
              {{ item.content_group.title }}
            </h3>
            <div class="row text-white my-5">
              <div class="col-12 text-center mt-5">
                <div
                  class="text mt-auto pb-4"
                  v-html="item.content_group.text"
                ></div>
              </div>
            </div>
          </div>

          <router-link
            class="
              btn btn-primary
              p-3
              mb-5
              text-small text-uppercase
              w-100
              text-bold
            "
            :to="'/rnds/' + pageData.acf.section_3_read_more_button.rnd_page.ID"
            v-if="pageData.acf.section_3_read_more_button.rnd_page"
          >
            {{ pageData.acf.section_3_read_more_button.anchor }}
          </router-link>
        </b-container>
      </div>
    </div>

    <div class="py-12" v-if="pageData && pageData.acf && pageData.acf.section_2_active && !pageData.acf.section_3_active"></div>

    <b-container
      fluid="xl"
      class="section-white pt-5 pb-5 bg-white"
      v-if="pageData && pageData.acf && pageData.acf.section_4_active"
    >
      <div v-for="item in pageData.acf.section_4_repeater">
        <SectionTitle class="pb-5">{{ item.content_group.title }}</SectionTitle>
        <b-row class="my-5">
          <b-col
            md="8"
            class="text-small"
            v-html="item.content_group.text"
          ></b-col>
          <b-col md="3" class="offset-md-1">
            <img
              :src="backendResourceDomain + item.content_group.image.url"
              alt=""
              class="img-fluid"
              v-if="item.content_group.image.url"
            />
          </b-col>
        </b-row>
      </div>
    </b-container>

    <b-container
      fluid
      class="py-4 bg-gray text-center text-primary"
      v-if="pageData && pageData.acf && pageData.acf.section_5_active"
    >
      <b-container fluid="xl">
        <div v-for="item in pageData.acf.section_5_repeater">
          <h3 class="title text-primary pb-5 my-5">
            {{ item.content_group.title }}
          </h3>
          <p class="text-bold" v-html="item.content_group.text"></p>
        </div>

        <a
          class="
            btn btn-primary
            p-3
            mt-5
            text-small text-uppercase
            w-100
            text-bold
          "
          :href="pageData.acf.section_5_read_more_button.url"
          v-if="pageData.acf.section_5_read_more_button.url"
        >
          {{ pageData.acf.section_5_read_more_button.anchor }}
        </a>
      </b-container>
    </b-container>

    <div
      class="bg-primary d-flex"
      v-if="pageData && pageData.acf && pageData.acf.case_study_items.length"
    >
      <b-container fluid="xl" class="bg-primary">
        <h3 class="text-center text-white my-5">
          {{ pageData.acf.section_6_title }}
        </h3>
        <case-studies-item
          v-for="(slide, index) in pageData.acf.case_study_items"
          v-if="slide.item_images && slide.item_images.sizes && slide.item_images.sizes.case_studies"
          :image="
            backendResourceDomain + slide.item_images.sizes.case_studies
          "
          :id="slide.ID"
          :title="slide.post_title"
          :text="slide.item_short_description"
          :reverse="reverse(index)"
        />
        <router-link
          class="btn bg-white p-3 text-small text-uppercase w-100 text-bold"
          v-if="pageData &&
          pageData.acf &&
          pageData.acf.section_6_read_more_button &&
          pageData.acf.section_6_read_more_button.url &&
          pageData.acf.section_6_read_more_button.anchor"
          :to="pageData.acf.section_6_read_more_button.url"
          v-text="pageData.acf.section_6_read_more_button.anchor"
          >SEE ALL BLOG SPOTS</router-link
        >
      </b-container>
    </div>

    <div
      class="bg-primary d-flex"
      v-if="pageData &&
        pageData.acf &&
        pageData.acf.section_7_content &&
        pageData.acf.section_7_content.section_7_home_page_button &&
        pageData.acf.section_7_content.section_7_home_page_button.url &&
        pageData.acf.section_7_content.section_7_home_page_button.anchor"
    >
      <b-container fluid="xl" class="bg-primary">
        <router-link
          class="
            btn btn-primary
            p-3
            text-small text-uppercase
            w-100
            text-bold
            redirect-btn
            mt-5
          "
          :to="pageData.acf.section_7_content.section_7_home_page_button.url"
          v-text="pageData.acf.section_7_content.section_7_home_page_button.anchor"
        >
          GO TO HOME PAGE
        </router-link>
      </b-container>
    </div>

    <!-- <div class="my-4 py-5 bg-white"></div> -->
  </main>
</template>

<script>
import axios from "@/axios";
import PageSlider from "../components/PageSlider";
import SectionTitle from "../components/SectionTitle.vue";
import BlueWaveContainer from "../components/BlueWaveContainer.vue";
import CaseStudiesItem from "../components/CaseStudiesItem.vue";

export default {
  components: {
    PageSlider,
    SectionTitle,
    BlueWaveContainer,
    CaseStudiesItem,
  },
  data() {
    return {
      isLoading: true,
      backendResourceDomain: "",
      currentPageSlug: "about-us",
      pageData: [],
      caseList: [],
    };
  },
  metaInfo() {
    if (!this.isLoading && this.pageData.acf.section_seo) {
      return {
        title: this.pageData.acf.section_seo.meta_title,
        meta: [
          {
            name: "description",
            content: this.pageData.acf.section_seo.meta_description,
          },
          {
            name: "keywords",
            content: this.pageData.acf.section_seo.meta_keywords,
          },
        ],
      };
    }
  },
  mounted() {
    this.getPageDataBySlug();
    this.getCases();
  },
  methods: {
    getPageDataBySlug: function () {
      axios
        .get("custom-pages/" + this.currentPageSlug)
        .then((response) => {
          // console.log("aboutus", response);
          this.pageData = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCases() {
      axios
        .get(`case-studies?&page=1`)
        .then((response) => {
          // console.log("cases", response);
          let object = response.data;
          object.pop();
          this.caseList = object;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    reverse(index) {
      if (index % 2 == 0) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.blue-wave {
  margin-bottom: -120px;
}
.worth-reading {
  background: url("/images/worth-reading.jpg");
  background-position: center;
  background-repeat: no-repeat;
  min-height: 733px;
  background-size: cover;
  z-index: -1;
  &--overlay {
    background: rgba(12, 37, 82, 0.6);
    height: 100%;
    width: 100%;
  }
  &--container {
    padding-top: 168px;
    padding-bottom: 100px;
    .text {
      line-height: 60px;
      ul {
        padding-left: 15px;
      }
    }
  }
  &__divider {
    height: 1px;
    background: white;
    margin: 40px 0;
  }
}
.section-white {
  margin-top: -100px;
}
.title {
  font-size: 48px;
}
</style>
